import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link, useNavigate, useParams } from 'react-router-dom'
import ContentWrapper from '../components/contentwrapper/ContentWrapper'
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { FaBorderAll, FaPlus, FaRegHandPointRight, FaTrashAlt } from 'react-icons/fa';
import { addFormData, fetchAllDataAPI, fetchAllMemberDataAPI, fetchCityNameByState, fetchPincodeByCity, getDataAPIToPincode, getProductDetailPrice, getSingleDataFromDatabase, updateDataAPI } from '../utils/api';
import { toast } from 'react-toastify';
import { InputGroup, Modal, Table } from 'react-bootstrap';
import { numberFormat } from '../components/numberFormat';


const initialState = {
    crm_order_id: '',
    order_number: '',
    order_name: '',
    order_mobile: '',
    order_alt_mobile: '',
    order_email: '',
    order_pincode: '',
    order_city: '',
    order_state: '',
    order_country: '',
    order_address: '',
    order_language_name: '',
    agent_id: '',
    order_confirm_status: '',
    order_source_name: '',
    order_confirm_to: '',
    order_remarks: "",
    payment_method: "",
    sub_total: "",
    shipping_charge: "",
    grand_total: "",
    total_balance: "",
    total_disc: "",
    vendor_name: "",
    order_advance_amt: "",
    product: []
}

const EditOrder = () => {
    const { slug } = useParams();
    const [validated, setValidated] = useState(false);
    const memberid = localStorage.getItem('memberdata');
    const navigate = useNavigate();
    const [values, setOrderValue] = useState(initialState);
    const [inputvalues, setInputsValue] = useState();
    const [pindata, setPindata] = useState([]);
    const [chanel, setChanel] = useState([]);
    const [dstate, setState] = useState([]);

    const [payshow, setPayShow] = useState(false);

    const handlePayClose = () => setPayShow(false);
    const handlePayShow = () => setPayShow(true);
    const [paymentlink, setPaymentLink] = useState([]);

    const [statename, setStateName] = useState("");
    const [city, setCity] = useState("");
    const [pincode, setPincode] = useState("");
    const [agentlist, setAgentList] = useState([]);

    const [cityname, setCityName] = useState([]);
    const [postname, setPostName] = useState([]);
    const [product, setProduct] = useState([]);
    const [productprice, setProductPrice] = useState([]);
    const [totalprice, setTotalPrice] = useState([]);
    const [finalprice, setFinalPrice] = useState([]);
    const [grandprice, setGrandPrice] = useState([]);
    const [discprice, setDiscPrice] = useState("");
    const [advamts, setAdvAmt] = useState([]);
    const [language, setLanguage] = useState([]);
    const [ostatus, setOrderStatus] = useState([]);
    const [dealer, setDealer] = useState([]);
    const [inputlist, setInputList] = useState([{ p_pid: '', p_name: '', p_qty: '', p_price: '', p_selling_price: '', p_sub_total: '', p_tsell_price: '', p_disc_price: '' }]);

    useEffect(() => {
        if (memberid) {
            getSingleEditOrder(slug);
            getAllActiveChanel();
            getAllDeliveryState();
            getAllActiveProductList();
            getAllLanguage();
            getAllOrderStatus();
            getAllAgentOwnCenter(memberid);
        }
    }, [slug, memberid]);

    const getSingleEditOrder = (slug) => {
        getSingleDataFromDatabase(`member-get-single-order-for-edit/${slug}`).then((res) => {
            setOrderValue(res.data.order);
            setInputList(res.data.order.product);
        })
    }

    const handleRowAddClick = () => {
        setInputList([...inputlist, { p_name: '', p_qty: '', p_price: '', p_selling_price: '', p_sub_total: '', p_tsell_price: '', p_disc_price: '' }])
    }
    const handleRowRemoveClick = index => {
        const list = [...inputlist];

        list.splice(index, 1);
        setInputList(list);

        let total = 0;
        let discount = 0;

        list.forEach(item => {
            total += parseFloat(item.p_tsell_price || 0);
            discount += parseFloat(item.p_disc_price || 0);
        });

        setTotalPrice(total);
        setDiscPrice(discount);
        setFinalPrice(total - discount);
        setGrandPrice(total - discount);
    };

    const getAllLanguage = () => {
        fetchAllDataAPI('member-get-all-language').then((res) => {
            if (res.data.status === 1) {
                setLanguage(res.data.language);
            }
        })
    }
    const getAllOrderStatus = () => {
        fetchAllDataAPI('member-get-all-order-status').then((res) => {
            if (res.data.status === 1) {
                setOrderStatus(res.data.statusdata);
                setDealer(res.data.dealer);
            }
        })
    }
    const getAllAgentOwnCenter = (memberid) => {
        fetchAllMemberDataAPI('member-get-all-agent-own-center', memberid).then((res) => {
            if (res.data.status === 1) {
                setAgentList(res.data.agentdata);
            }
        })
            .catch((error) => {

            });
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            event.stopPropagation();
        } else {
            try {
                // Ensure inputlist contains valid data
                if (!inputlist || inputlist.length === 0) {
                    toast.error("No products added to the order.");
                    return;
                }

                // Calculate subtotal by summing the 'p_sub_total' from all products
                let calculatedSubTotal = inputlist.reduce((acc, product) => {
                    return acc + parseFloat(product.p_sub_total || 0);
                }, 0);

                // Calculate the total discount by summing the 'p_disc_price' from all products
                let calculatedDiscount = inputlist.reduce((acc, product) => {
                    return acc + parseFloat(product.p_disc_price || 0);
                }, 0);

                // Ensure advance amount is a valid number
                let calculatedAdvanceAmt = parseFloat(advamts) || 0; // Default to 0 if NaN
                let calculatedShippingCharge = parseFloat(values.shipping_charge || 0); // Default to 0 if empty

                // Calculate the final total (total_balance) and grand total
                let calculatedFinalPrice = calculatedSubTotal - calculatedAdvanceAmt;
                let calculatedGrandTotal = calculatedFinalPrice + calculatedShippingCharge;

                // Ensure all calculated values are valid numbers; if not, set to 0
                calculatedSubTotal = isNaN(calculatedSubTotal) ? 0 : calculatedSubTotal;
                calculatedDiscount = isNaN(calculatedDiscount) ? 0 : calculatedDiscount;
                calculatedFinalPrice = isNaN(calculatedFinalPrice) ? 0 : calculatedFinalPrice;
                calculatedGrandTotal = isNaN(calculatedGrandTotal) ? 0 : calculatedGrandTotal;

                // Prepare data object for API submission
                const data = {
                    center_mis_id: memberid,
                    order_number: values.order_number,
                    agent_id: values.agent_id,
                    order_name: values.order_name,
                    order_mobile: values.order_mobile,
                    order_alt_mobile: values.order_alt_mobile,
                    order_email: values.order_email,
                    order_source_name: values.order_source_name,
                    order_post_office: values.order_post_office,
                    order_pincode: values.order_pincode,
                    order_city: values.order_city,
                    order_state: values.order_state,
                    order_country: "India",
                    order_address: values.order_address,
                    order_advance_amt: calculatedAdvanceAmt.toFixed(2),
                    sub_total: calculatedSubTotal.toFixed(2), // Ensure two decimal places
                    shipping_charge: calculatedShippingCharge.toFixed(2),
                    payment_method: values.payment_method,
                    total_disc: calculatedDiscount.toFixed(2), // Total discount calculated
                    total_balance: finalprice.toFixed(2), // Total balance calculated
                    grand_total: finalprice.toFixed(2), // Grand total calculated
                    order_remarks: values.order_remarks,
                    order_language_name: values.order_language_name,
                    order_confirm_status: values.order_confirm_status,
                    order_confirm_to: values.order_confirm_to,
                    inputvalues: inputlist // Use the inputlist as input values
                };

                // Submit the data using your API
                updateDataAPI(`member-updated-single-order-form-member/${slug}`, data).then((res) => {
                    if (res.data.status === 1) {
                        toast.success("Order has been updated successfully!");
                        navigate('/all-orders');
                    } else {
                        toast.error("Something went wrong!");
                    }
                });

            } catch (error) {
                toast.error("An error occurred while submitting the form!");
            }
        }

        setValidated(true);
    };

    const handlePincode = e => {
        setPincode(e.target.value)
        const pincode = e.target.value;
        getDataAPIToPincode('member-get-post-office-name-by-pincode-data', pincode).then((res) => {
            if (res?.data.status === 1) {
                setPostName(res?.data.postnamedata);
            }
            else {
                toast.warning("Check your connection!!");
            }
        })
    }
    const getAllActiveChanel = () => {
        fetchAllDataAPI('member-get-all-active-chanel-data').then((resp) => {
            if (resp.data.status === 1) {
                setChanel(resp.data.achaneldata)
            }
        })
    }
    const getAllDeliveryState = () => {
        fetchAllDataAPI('member-get-all-delivery-state-data').then((resp) => {
            if (resp.data.status === 1) {
                setState(resp.data.statedata)
            }
        })
    }
    const getAllActiveProductList = () => {
        fetchAllDataAPI('member-get-all-active-product-data').then((resp) => {
            if (resp.data.status === 1) {
                setProduct(resp.data.productdata)
            }
        })
    }
    const handleSelectState = (e) => {
        setStateName(e.target.value)
        const statename = e.target.value;
        fetchCityNameByState('member-get-city-name-by-state-data', statename).then((res) => {
            if (res.data.status === 1) {
                setCityName(res.data.citydata)
            }
        })
    }
    const handleSelectCity = (e) => {
        setCity(e.target.value)
        const cityname = e.target.value;
        fetchPincodeByCity('member-get-pincode-by-city-data', cityname).then((res) => {
            if (res.data.status === 1) {
                setPindata(res.data.pincoddata)
            }
        })
    }
    let handleInputs = e => {
        setOrderValue({
            ...values,
            [e.target.name]: e.target.value,
        });
    };
    let handleMultipleInputs = (i, e) => {
        let newFormValues = [...inputlist];
        newFormValues[i][e.target.name] = e.target.value;
        setInputsValue(newFormValues);
    };

    let handleProductSelect = (i, e) => {
        let newProductid = [...inputlist];
        const productid = e.target.value; // Correctly use e.target.value

        // Update product ID or name here
        newProductid[i]['p_pid'] = productid;

        getProductDetailPrice('member-get-product-details-data', productid).then((resp) => {
            if (resp.data.status === 1) {
                // Update the inputlist with new product details
                const productDetails = resp.data.product;
                newProductid[i]['p_name'] = productDetails.product_name;  // Use product name properly
                newProductid[i]['p_price'] = productDetails.product_price;
                newProductid[i]['p_selling_price'] = productDetails.product_sell_price;
                newProductid[i]['p_qty'] = newProductid[i]['p_qty'] || 1;
                newProductid[i]['p_sub_total'] = productDetails.product_sell_price * newProductid[i]['p_qty'];
                newProductid[i]['p_tsell_price'] = newProductid[i]['p_sub_total'];
                newProductid[i]['p_disc_price'] = newProductid[i]['p_disc_price'] || 0;

                // Update input state
                setInputsValue(newProductid);

                // Calculate total prices based on updated inputlist
                const total = newProductid.reduce((acc, item) => acc + parseFloat(item.p_tsell_price || 0), 0);
                const discount = newProductid.reduce((acc, item) => acc + parseFloat(item.p_disc_price || 0), 0);

                // Update total, final, and grand prices
                setTotalPrice(total);
                setFinalPrice(total);
                setGrandPrice(total);

            } else {
                console.error('Product data fetch error');
            }
        }).catch(err => {
            console.error('Error fetching product details', err);
        });
    };

    let handleQuantitySelect = (i, e) => {
        let newProductid = [...inputlist];
        const productQty = parseInt(e.target.value) || 1;

        if (productQty >= 1) {
            newProductid[i]['p_qty'] = productQty;
            newProductid[i]['p_sub_total'] = newProductid[i]['p_selling_price'] * productQty;
            newProductid[i]['p_tsell_price'] = newProductid[i]['p_sub_total'];
            newProductid[i]['p_disc_price'] = (newProductid[i]['p_selling_price'] * productQty) - newProductid[i]['p_tsell_price'];

            setInputList(newProductid);

            let total = 0;
            let dtotal = 0;

            newProductid.forEach((item) => {
                total += parseFloat(item.p_tsell_price) || 0;
                dtotal += parseFloat(item.p_disc_price) || 0;
            });

            setTotalPrice(total);
            setFinalPrice(total);
            setGrandPrice(total);
            setDiscPrice(dtotal);
        } else {
            toast.warning("Product quantity must be at least one");
        }
    };

    let handleTotalSellPrice = (i, e) => {
        let newProductid = [...inputlist];

        if (!newProductid[i]) {
            console.error(`Index ${i} out of bounds for newProductid`);
            return;
        }

        const productTSellPrice = e.target.value;

        if (productTSellPrice >= 0) {
            newProductid[i]['p_tsell_price'] = productTSellPrice;

            newProductid[i]['p_disc_price'] =
                (newProductid[i]['p_selling_price'] * newProductid[i]['p_qty']) - productTSellPrice;

            let total = 0;
            let dtotal = 0;

            newProductid.map((newProductid, index) => (
                total += parseInt(newProductid.p_sub_total) - parseInt(newProductid.p_disc_price),
                dtotal += parseInt(newProductid.p_disc_price)
            ))


            setTotalPrice(total);
            setFinalPrice(total);
            setGrandPrice(total);
            setDiscPrice(dtotal);

            setInputList(newProductid);
        } else {
            toast.warning("Product quantity must be at least one");
        }
    };

    const handleAdvanceAmt = (e) => {
        const advamt = parseFloat(e.target.value) || 0;

        if (discprice) {
            const finalprice = grandprice - advamt;
            setFinalPrice(finalprice); //
        } else {
            const finalprice = values.total_balance - advamt;
            setFinalPrice(finalprice);
        }

        setAdvAmt(advamt);
        setOrderValue({
            ...values,
            order_advance_amt: advamt
        });
    };
    const handleSendPaymentLink = () => {
        if (values?.order_name === undefined || values?.order_mobile === undefined) {
            toast.warning("Customer name and mobile number should not be blank!")
        }
        else {
            const advdata = { agent_id: memberid, order_name: values?.order_name, order_mobile: values?.order_mobile, order_advance_amt: advamts }
            addFormData('member-send-payment-link-for-advance-data', advdata).then((resp) => {
                if (resp.data.status === 1) {
                    setPaymentLink(resp.data.data);
                    setPayShow(true);
                }
            })
        }
    }
    return (
        <div className="misDashboard">
            <Modal show={payshow} onHide={handlePayClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Payment Link and TRN ID</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table striped>
                        <tr>
                            <th>Amount</th>
                            <th>{numberFormat(paymentlink?.amount / 100)}</th>
                        </tr>
                        <tr>
                            <th>Merchant Id</th>
                            <th>{paymentlink?.merchantId}</th>
                        </tr>
                        <tr>
                            <th>TRN ID</th>
                            <th>{paymentlink?.transactionId}</th>
                        </tr>
                        <tr>
                            <th>Pay Link</th>
                            <th>{paymentlink?.payLink}</th>
                        </tr>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handlePayClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Helmet>
                <title>SATKARTAR:AGENT::ADD NEW ORDER</title>
            </Helmet>
            <ContentWrapper>
                <div className="page-wrapper breadcum">
                    <div className="page-breadcrumb btnbreadcrum d-none d-sm-flex align-items-center justify-between">
                        <div className="">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 p-0">
                                    <li className="breadcrumb-item">
                                        <Link to="/dashboard">Home</Link>
                                    </li>
                                    <li className="breadcrumb-item" aria-current="page">Add New Order</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div className="col-12 col-lg-12 col-xl-12 marbottom">
                        <div className="card border-top border-0 border-4 border-danger">
                            <div className="card-body">
                                <div className="card-title d-flex align-items-center">
                                    <div className="form-heading">
                                        <FaBorderAll className="formIcons" />
                                        <h5 className="mb-0 text-danger">ADD New Order</h5>
                                    </div>
                                </div>
                                <hr />
                                <Form noValidate validated={validated} onSubmit={handleSubmit} method="POST">
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="3" controlId="validationCustom01">
                                            <Form.Label>Name</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="Customer Name"
                                                name="order_name"
                                                onChange={handleInputs}
                                                value={values?.order_name}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a valid customer name
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="3" controlId="validationCustom02">
                                            <Form.Label>Mobile Number</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="Enter the Mobile Number"
                                                name="order_mobile"
                                                onChange={handleInputs}
                                                pattern="[6789][0-9]{9}"
                                                maxLength={10}
                                                minLength={10}
                                                value={values?.order_mobile}
                                                readOnly={true}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a valid customer mobile number
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="3" controlId="validationCustom03">
                                            <Form.Label>Alternate Mobile Number</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter the Alternate Mobile Number"
                                                name="order_alt_mobile"
                                                onChange={handleInputs}
                                                pattern="[6789][0-9]{9}"
                                                maxLength={10}
                                                minLength={10}
                                                value={values?.order_alt_mobile}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a valid customer mobile number
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="3" controlId="validationCustom04">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control
                                                type="email"
                                                placeholder="Enter the Email address"
                                                name="order_email"
                                                onChange={handleInputs}
                                                value={values?.order_email}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a valid Email Address
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="3" controlId="validationCustom05">
                                            <Form.Label>Select Channel OR Source Name</Form.Label>
                                            <Form.Select
                                                required
                                                placeholder="Category Name"
                                                name="order_source_name"
                                                value={values?.order_source_name || ''}
                                                onChange={handleInputs}
                                            >
                                                <option value="">Select Source</option>
                                                {chanel?.map((ch, index) => (
                                                    <option key={index} value={ch?.chanel_name}>
                                                        {ch?.chanel_name}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a valid channel name or source name
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} md="3" controlId="validationCustom05">
                                            <Form.Label>Select Language</Form.Label>
                                            <Form.Select
                                                required
                                                placeholder="Language Name"
                                                name="order_language_name"
                                                value={values?.order_language_name || ''}
                                                onChange={handleInputs}
                                            >
                                                <option value="">Select Language</option>
                                                {language?.map((lang, index) => (
                                                    <option key={index} value={lang?.language_name}>
                                                        {lang?.language_name}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a valid language name
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="3" controlId="validationCustom06">
                                            <Form.Label>State</Form.Label>
                                            <Form.Select
                                                required
                                                placeholder="Enter the State"
                                                name="order_state"
                                                onChange={handleSelectState}
                                            >
                                                <option value={values?.order_state}>{values?.order_state}</option>
                                                {dstate?.map((dstate, index) => (
                                                    <option key={index} value={dstate?.state_name}>
                                                        {dstate?.state_name}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a valid state
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="3" controlId="validationCustom07">
                                            <Form.Label>City/Division</Form.Label>
                                            <Form.Select
                                                required
                                                placeholder="Enter the City"
                                                name="order_city"
                                                onChange={handleSelectCity}>
                                                <option value={values?.order_city}>{values?.order_city}</option>
                                                {cityname?.map((cityname, index) => (
                                                    <option key={index} value={cityname?.district}>{cityname?.district}</option>
                                                ))}
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a valid city
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="3" controlId="validationCustom08">
                                            <Form.Label>Pincode</Form.Label>
                                            <Form.Select
                                                required
                                                placeholder="Enter the Pincode"
                                                name="order_pincode"
                                                onChange={handlePincode}>
                                                <option value={values?.order_pincode}>{values?.order_pincode}</option>
                                                {pindata?.map((pindata, index) => (
                                                    <option key={index} value={pindata?.pincode}>{pindata?.pincode}</option>
                                                ))}
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a valid pincode
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="3" controlId="validationCustom09">
                                            <Form.Label>Post Office Name</Form.Label>
                                            <Form.Select
                                                required
                                                placeholder="Enter the Pincode"
                                                name="order_post_office"
                                                onChange={handleInputs}>
                                                <option value={values?.order_post_office}>{values?.order_post_office}</option>
                                                {postname?.map((postname, index) => (
                                                    <option key={index} value={postname?.office_name}>{postname?.office_name}</option>
                                                ))}
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a valid Post Office Name
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="3" controlId="validationCustom010">
                                            <Form.Label>Country</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter the State"
                                                name="order_country"
                                                value="India"
                                                readOnly={true}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a valid country
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="3" controlId="validationCustom011">
                                            <Form.Label>Remarks</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter the Remarks"
                                                name="order_remarks"
                                                onChange={handleInputs}
                                                value={values?.order_remarks}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a valid country
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="3" controlId="validationCustom12">
                                            <Form.Label>Select Order Type</Form.Label>
                                            <Form.Select
                                                required
                                                placeholder="Order Type Name"
                                                name="order_confirm_status"
                                                value={values?.order_confirm_status || ''}
                                                onChange={handleInputs}
                                            >
                                                <option value="">Select Order Type</option>
                                                {ostatus?.map((ostatus, index) => (
                                                    <option key={index} value={ostatus?.ostatus_name}>
                                                        {ostatus?.ostatus_name}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a valid order type
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="3" controlId="validationCustom12">
                                            <Form.Label>Select Agent</Form.Label>
                                            <Form.Select
                                                required
                                                placeholder="Select Agent"
                                                name="agent_id"
                                                value={values?.agent_id || ''}
                                                onChange={handleInputs}
                                            >
                                                <option value="">Select Agent</option>
                                                {agentlist?.map((agent, index) => (
                                                    <option key={index} value={agent?.center_agent_id}>
                                                        {agent?.agent_name}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a valid agent
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="3" controlId="validationCustom12">
                                            <Form.Label>Select Confirm TO</Form.Label>
                                            <Form.Select
                                                required
                                                placeholder="Select Confirm TO"
                                                name="order_confirm_to"
                                                value={values?.order_confirm_to || ''}
                                                onChange={handleInputs}
                                            >
                                                <option value="">Select Confirm TO</option>
                                                {dealer?.map((dealer, index) => (
                                                    <option key={index} value={dealer?.dealer_name}>
                                                        {dealer?.dealer_name}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a valid confirm-to name
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="12" controlId="validationCustom13">
                                            <Form.Label>Full Address</Form.Label>
                                            <Form.Control
                                                required
                                                as="textarea"
                                                type="text"
                                                placeholder="Full Address"
                                                name="order_address"
                                                onChange={handleInputs}
                                                value={values?.order_address}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a valid full address
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Row>
                                    <Row className="productdetails table-responsive">
                                        <h6>Product Details</h6>
                                        <Table className="table-sm">
                                            <thead>
                                                <tr className="productdetailhead">
                                                    <th width={300}>Product Name</th>
                                                    <th width={150}>QTY</th>
                                                    <th width={150}>Product Price</th>
                                                    <th width={150}>Selling Price</th>
                                                    <th width={150}>Subtotal</th>
                                                    <th width={150}>Total Sell Price</th>
                                                    <th width={150}>Disc Price</th>
                                                    <th width={100}>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {inputlist.map((element, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>
                                                                <Form.Group as={Col} md="12" controlId={`validationCustom11${index}`}>
                                                                    <Form.Select
                                                                        required
                                                                        placeholder="Select Product"
                                                                        name="p_name"
                                                                        value={element.p_pid || ""}
                                                                        onChange={e => handleProductSelect(index, e)}>
                                                                        <option value="">Select Product</option>
                                                                        {product?.map((product, index) => (
                                                                            <option key={index} value={product?.product_id}>{product?.product_name}</option>
                                                                        ))}
                                                                    </Form.Select>
                                                                </Form.Group>
                                                            </td>
                                                            <td>
                                                                <Form.Group as={Col} md="12" controlId={`validationCustom12${index}`}>
                                                                    <Form.Control
                                                                        required
                                                                        type="text"
                                                                        placeholder="Product QTY"
                                                                        name="p_qty"
                                                                        pattern="[0-9]*"
                                                                        minLength={1}
                                                                        onChange={e => handleQuantitySelect(index, e)}
                                                                        value={element.p_qty || ""}
                                                                    />
                                                                </Form.Group>
                                                            </td>
                                                            <td>
                                                                <Form.Group as={Col} md="12" controlId={`validationCustom13${index}`}>
                                                                    <Form.Control
                                                                        required
                                                                        type="text"
                                                                        placeholder="Product Price"
                                                                        name="p_price"
                                                                        pattern="[0-9]*\.?[0-9]*"
                                                                        readOnly={true}
                                                                        onChange={e => handleMultipleInputs(index, e)}
                                                                        value={element.p_price || ""}
                                                                    />
                                                                </Form.Group>
                                                            </td>
                                                            <td>
                                                                <Form.Group as={Col} md="12" controlId={`validationCustom14${index}`}>
                                                                    <Form.Control
                                                                        required
                                                                        type="text"
                                                                        placeholder="Selling Price"
                                                                        name="p_selling_price"
                                                                        pattern="[0-9]*\.?[0-9]*"
                                                                        readOnly={true}
                                                                        onChange={e => handleMultipleInputs(index, e)}
                                                                        value={element.p_selling_price || ""}
                                                                    />
                                                                </Form.Group>
                                                            </td>
                                                            <td>
                                                                <Form.Group as={Col} md="12" controlId={`validationCustom15${index}`}>
                                                                    <Form.Control
                                                                        required
                                                                        type="text"
                                                                        placeholder="Sub Total Price"
                                                                        name="p_sub_total"
                                                                        pattern="[0-9]*\.?[0-9]*"
                                                                        readOnly={true}
                                                                        onChange={e => handleMultipleInputs(index, e)}
                                                                        value={element.p_sub_total || ""}
                                                                    />
                                                                </Form.Group>
                                                            </td>
                                                            <td>
                                                                <Form.Group as={Col} md="12" controlId={`validationCustom15${index}`}>
                                                                    <Form.Control
                                                                        required
                                                                        type="text"
                                                                        placeholder="Total Sell Price"
                                                                        name="p_tsell_price"
                                                                        pattern="[0-9]*\.?[0-9]*"
                                                                        onChange={e => handleTotalSellPrice(index, e)}
                                                                        value={element.p_tsell_price || ""}
                                                                    />
                                                                </Form.Group>
                                                            </td>
                                                            <td>
                                                                <Form.Group as={Col} md="12" controlId={`validationCustom15${index}`}>
                                                                    <Form.Control
                                                                        required
                                                                        type="text"
                                                                        placeholder="0.00"
                                                                        name="p_disc_price"
                                                                        readOnly={true}
                                                                        pattern="[0-9]*\.?[0-9]*"
                                                                        value={element.p_disc_price || ""}
                                                                    />
                                                                </Form.Group>
                                                            </td>
                                                            {(() => {
                                                                if (inputlist.length !== index + 1) {
                                                                    return (
                                                                        <td>
                                                                            <span className="btnremoverow" onClick={() => handleRowRemoveClick(index)}><FaTrashAlt /> Remove</span>
                                                                        </td>
                                                                    )
                                                                }
                                                            })()}
                                                            {(() => {
                                                                if (inputlist.length - 1 === index) {
                                                                    return (
                                                                        <td>
                                                                            <span className="btnaddrow" onClick={handleRowAddClick}><FaPlus /> Add More</span>
                                                                        </td>
                                                                    )
                                                                }
                                                            })()}
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </Table>
                                    </Row>
                                    <Row>
                                        <div className="col-md-6 offset-xxl-8 pricedetails">
                                            <div className="pricebody">
                                                <InputGroup size="sm" className="mb-3">
                                                    <InputGroup.Text id="inputGroup-sizing-sm" className="inputwidth">Sub Total(₹)</InputGroup.Text>
                                                    <Form.Control
                                                        aria-label="Small"
                                                        aria-describedby="inputGroup-sizing-sm"
                                                        placeholder="0.00"
                                                        name="sub_total"
                                                        value={parseFloat(totalprice + discprice) || values?.sub_total || (parseFloat(totalprice + discprice || 0)).toFixed(2)}
                                                        readOnly={true}
                                                        pattern="[0-9]*\.?[0-9]*"
                                                    />
                                                </InputGroup>
                                            </div>

                                            <div className="pricebody">
                                                <InputGroup size="sm" className="mb-3">
                                                    <InputGroup.Text id="inputGroup-sizing-sm" className="inputwidth">Shipping Charge(₹)</InputGroup.Text>
                                                    <Form.Control
                                                        aria-label="Small"
                                                        aria-describedby="inputGroup-sizing-sm"
                                                        placeholder="0.00"
                                                        name="shipping_charge"
                                                        onChange={handleInputs}
                                                        defaultValue={0}
                                                        readOnly={true}
                                                        pattern="[0-9]*\.?[0-9]*"
                                                        value={values?.shipping_charge}
                                                    />
                                                </InputGroup>
                                            </div>
                                            <div className="pricebody">
                                                <InputGroup size="sm" className="mb-3">
                                                    <InputGroup.Text id="inputGroup-sizing-sm" className="inputwidth">Payment Method</InputGroup.Text>
                                                    <Form.Select
                                                        required
                                                        placeholder="Select Product"
                                                        name="payment_method"
                                                        value={values?.payment_method}
                                                        onChange={handleInputs}>
                                                        <option value="">Payment Method</option>
                                                        <option value="COD">COD</option>
                                                        <option value="ONLINE">ONLINE</option>
                                                    </Form.Select>
                                                </InputGroup>
                                            </div>
                                            <div className="pricebody">
                                                <InputGroup size="sm" className="mb-3">
                                                    <InputGroup.Text id="inputGroup-sizing-sm" className="inputwidth">Discount(₹)</InputGroup.Text>
                                                    <Form.Control
                                                        aria-label="Small"
                                                        aria-describedby="inputGroup-sizing-sm"
                                                        placeholder="0.00"
                                                        name="total_disc"
                                                        readOnly={true}
                                                        pattern="[0-9]*\.?[0-9]*"
                                                        value={parseFloat(discprice || values?.total_disc || 0).toFixed(2)}
                                                    />
                                                </InputGroup>
                                            </div>

                                            <div className="pricebody">
                                                <InputGroup size="sm" className="mb-3">
                                                    <InputGroup.Text id="inputGroup-sizing-sm" className="inputwidth">Advance Amount(₹)</InputGroup.Text>
                                                    <Form.Control
                                                        aria-label="Small"
                                                        aria-describedby="inputGroup-sizing-sm"
                                                        placeholder="0.00"
                                                        name="order_advance_amt"
                                                        onChange={e => handleAdvanceAmt(e)}
                                                        pattern="[0-9]*\.?[0-9]*"
                                                        value={values?.order_advance_amt || ""}
                                                    />
                                                </InputGroup>
                                            </div>
                                            <div className="pricebody">
                                                <InputGroup size="sm" className="mb-3">
                                                    <InputGroup.Text id="inputGroup-sizing-sm" className="inputwidth">Total Balance(₹)</InputGroup.Text>
                                                    <Form.Control
                                                        aria-label="Small"
                                                        aria-describedby="inputGroup-sizing-sm"
                                                        placeholder="0.00"
                                                        name="total_balance"
                                                        value={parseFloat(finalprice) || values?.grand_total || (parseFloat(grandprice || 0)).toFixed(2)}
                                                        readOnly={true}
                                                        pattern="[0-9]*\.?[0-9]*"
                                                    />
                                                </InputGroup>
                                            </div>
                                            <div className="pricebody">
                                                <InputGroup size="sm" className="mb-3">
                                                    <InputGroup.Text id="inputGroup-sizing-sm" className="inputwidth">Grand Total(₹)</InputGroup.Text>
                                                    <Form.Control
                                                        aria-label="Small"
                                                        aria-describedby="inputGroup-sizing-sm"
                                                        placeholder="0.00"
                                                        name="grand_total"
                                                        value={parseFloat(finalprice) || values?.grand_total || (parseFloat(grandprice || 0)).toFixed(2)}
                                                        readOnly={true}
                                                        onChange={handleInputs}
                                                    />
                                                </InputGroup>
                                            </div>
                                        </div>
                                    </Row>
                                    <div className="btnorders">
                                        {parseFloat(advamts) > 0 && values.payment_method === "ONLINE" ? (
                                            <span onClick={handleSendPaymentLink} className="btn btn-sm btn-secondary">
                                                Send Payment Link
                                            </span>
                                        ) : null}

                                        <Button type="submit" className="btn btn-sm btn-success">Generate Order</Button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        </div>
    )
}

export default EditOrder