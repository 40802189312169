import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import ContentWrapper from '../components/contentwrapper/ContentWrapper'
import { Link, useNavigate } from 'react-router-dom';
import { addDataAPI, addFormData, fetchFilterByOrderDataAPI } from '../utils/api';
import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton';

import { Button, Col, Form, Modal, Row, Table } from 'react-bootstrap';
import { MdFilterTiltShift } from 'react-icons/md';
import { FaRegHandPointRight } from 'react-icons/fa';
import { numberFormat } from '../components/numberFormat';
import moment from 'moment';

const PaymentHistory = () => {
    const [paymenthistory, setPaymentHistory] = useState([]);
    const [isLoading, setIsloading] = useState(false);
    const memberid = localStorage.getItem("memberdata");
    const navigate = useNavigate();

    const [values, setValue] = useState({
        payment_added_by: '',
        payment_orderid: '',
        payment_amount: '',
        payment_trnid: '',
        payment_date: '',
    });
    const [validated, setValidated] = useState(false);

    const [payshow, setPayShow] = useState(false);

    const handlePayClose = () => setPayShow(false);
    const [paymentlink, setPaymentLink] = useState([]);


    useEffect(() => {
        if (memberid) {
            navigate('/payment-history');
        }
        else {
            navigate("/");
        }
    }, [memberid]);

    const handleSearchByOrderNumber = async (e) => {
        setIsloading(true);
        const filtervalue = e.target.value;
        e.preventDefault();

        if (filtervalue) {
            try {
                const res = await fetchFilterByOrderDataAPI('member-search-payment-history', filtervalue);

                if (res.data.status === 1 && res.data.order) {
                    setIsloading(false);
                    setPaymentHistory(res.data.history);
                    setValue((prevValues) => ({
                        ...prevValues,
                        payment_amount: prevValues.payment_amount === '' && res.data.order.total_balance ? res.data.order.total_balance : prevValues.payment_amount,
                    }));
                } else {
                    setPaymentHistory([]);
                    setIsloading(false);
                }
            } catch (error) {
                setIsloading(false);
                toast.error("An error occurred while fetching payment history.");
            }
        } else {
            setPaymentHistory([]);
            setIsloading(false);
        }
    };

    const handlePaymentHistory = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
        }
        else {
            try {
                const data = {
                    payment_added_by: memberid,
                    payment_orderid: values.payment_orderid,
                    payment_amount: values.payment_amount,
                    payment_trnid: values.payment_trnid,
                    payment_date: values.payment_date,
                }
                addDataAPI('member-add-payment-history', data).then((res) => {
                    if (res.data.status === 1) {
                        toast.success("Payment History has been added!!");
                    }
                    else if (res.data.status === 2) {
                        toast.warning("This payment already exits!!");
                    }
                    else {
                        toast.error("Something is worng!!");
                    }
                })
            }
            catch (error) {
                toast.error("Something is worng!!");
            }
        }
        setValidated(true);
    };
    let handleInputs = (e) => {
        setValue({
            ...values,
            [e.target.name]: e.target.value,
        });
    };

    const handleSendPaymentLink = () => {
        if (values.payment_orderid != "" || values.payment_amount != "") {
            const data = {
                orderid: values.payment_orderid,
                amount: values.payment_amount,
            }
            addFormData('member-send-payment-link-form-history', data).then((resp) => {
                console.log("res", resp)
                if (resp.data.status === 1) {
                    setPaymentLink(resp.data.data);
                    setPayShow(true);
                }
            })
        }
        else {
            toast.warning("Order ID Should not be blank")
        }
    }

    return (
        <div className="misDashboard">
            <Modal show={payshow} onHide={handlePayClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Payment Link and TRN ID</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table striped>
                        <tr>
                            <th>Amount</th>
                            <th>{numberFormat(paymentlink?.amount / 100)}</th>
                        </tr>
                        <tr>
                            <th>Merchant Id</th>
                            <th>{paymentlink?.merchantId}</th>
                        </tr>
                        <tr>
                            <th>TRN ID</th>
                            <th>{paymentlink?.transactionId}</th>
                        </tr>
                        <tr>
                            <th>Pay Link</th>
                            <th>{paymentlink?.payLink}</th>
                        </tr>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handlePayClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Helmet>
                <title>SATKARTAR:MEMBER:PAYMENT HISTORY</title>
            </Helmet>
            <ContentWrapper>
                <div className="page-wrapper breadcum">
                    <div className="page-breadcrumb d-none d-sm-flex align-items-center justify-content-between">
                        <div className="">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 p-0">
                                    <li className="breadcrumb-item">
                                        <Link to="/dashboard">Home</Link>
                                    </li>
                                    <li className="breadcrumb-item" aria-current="page">Payment History</li>
                                </ol>
                            </nav>
                        </div>
                    </div>

                    <div className="col-12 col-lg-12 col-xl-12">
                        <div className="card border-top border-0 border-4 border-danger">
                            <div className="card-body">
                                <div className="card-title d-flex align-items-center">
                                    <div className="form-heading">
                                        <MdFilterTiltShift className="formIcons" />
                                        <h5 className="mb-0 text-danger">Payment History</h5>
                                    </div>
                                </div>
                                <hr />
                                <Form noValidate validated={validated} onSubmit={handlePaymentHistory} method="POST">
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="3" controlId="validationCustom01">
                                            <Form.Label>Order Number</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="Enter Order Number"
                                                name="payment_orderid"
                                                onChange={handleInputs}
                                                onKeyUp={handleSearchByOrderNumber}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a valid order number
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="3" controlId="validationCustom02">
                                            <Form.Label>Enter Amount</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="Enter Amount"
                                                name="payment_amount"
                                                pattern="[0-9]*\.?[0-9]*"
                                                onChange={handleInputs}
                                                value={values.payment_amount || ''}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a valid amount
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="3" controlId="validationCustom03">
                                            <Form.Label>TRN ID</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="Enter TRN ID"
                                                name="payment_trnid"
                                                onChange={handleInputs}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a valid amount
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="3" controlId="validationCustom03">
                                            <Form.Label>Payment Date</Form.Label>
                                            <Form.Control
                                                required
                                                type="date"
                                                placeholder="Enter TRN ID"
                                                name="payment_date"
                                                onChange={handleInputs}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a valid amount
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Row>
                                    <Row>
                                        <Form.Group as={Col} md="3" className="mb-2">
                                            <Button type="submit" className="btn btn-sm btn-primary">Submit</Button>
                                            {parseFloat(values.payment_amount) > 0 ? (
                                                <span
                                                    onClick={handleSendPaymentLink}
                                                    style={{ marginLeft: "10px" }}
                                                    type="submit"
                                                    className="btn btn-sm btn-warning"
                                                >
                                                    Send Payment Link
                                                </span>
                                            ) : (
                                                <span style={{ marginLeft: "10px", opacity: 0.5 }} className="btn btn-sm btn-warning disabled">
                                                    Send Payment Link
                                                </span>
                                            )}
                                        </Form.Group>
                                    </Row>
                                </Form>
                            </div>
                        </div>
                    </div>

                    <div className="card tablecard">
                        <div className="table-responsive">
                            <Table size="sm" className="table-sm" responsive="sm" striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Order No.</th>
                                        <th>Amount</th>
                                        <th>TRN ID</th>
                                        <th>Payment Date</th>
                                        <th>Created Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {isLoading ? (
                                        [...Array(6)].map((_, i) => (
                                            <tr key={i}>
                                                {Array.from({ length: 6 }).map((_, index) => (
                                                    <td key={index}>
                                                        <Skeleton animation="wave" variant="text" />
                                                    </td>
                                                ))}
                                            </tr>
                                        ))
                                    )
                                        :
                                        paymenthistory ?
                                            paymenthistory?.map((history, index) => (
                                                <tr>
                                                    <td>{index + 1}</td>
                                                    <td>{history?.payment_orderid}</td>
                                                    <td>{numberFormat(history?.payment_amount)}</td>
                                                    <td>{history?.payment_trnid}</td>
                                                    <td>{moment(history?.payment_date).format('Do MMMM YYYY')}</td>
                                                    <td>{moment(history?.payment_created).format('Do MMMM YYYY')}</td>
                                                </tr>
                                            ))
                                            :
                                            <tr >
                                                <td colSpan={10}><p className="text-center">Data not founds......</p></td>
                                            </tr>
                                    }
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th>#</th>
                                        <th>Order No.</th>
                                        <th>Amount</th>
                                        <th>TRN ID</th>
                                        <th>Payment Date</th>
                                        <th>Created Date</th>
                                    </tr>
                                </tfoot>
                            </Table>
                        </div>
                    </div>
                </div>
            </ContentWrapper >
        </div >
    )

}

export default PaymentHistory