import React, { useState, useEffect } from 'react'
import favicon from "../assets/favicon.png"
import { Helmet } from 'react-helmet'
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { loginAPI } from '../utils/api';
import loadingif from "../assets/loading-gif.gif";
import { Col, Form } from 'react-bootstrap';
import { FaRegHandPointRight } from "react-icons/fa";

const Home = () => {
    const [values, setInputValue] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [validated, setValidated] = useState(false);

    const memberdata = localStorage.getItem('memberdata');
    useEffect(() => {
        if (memberdata) {
            navigate("/dashboard");
        }
        else {
            navigate("/");
        }
    }, [memberdata]);


    const handleLoginSubmit = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
        }
        else {
            setLoading(true);
            loginAPI("member-login-verify-api", values).then((res) => {
                if (res.data.status === 1) {
                    localStorage.setItem("memberdata", res.data.memberdata);
                    toast.success("Login successfully!!!");
                    setLoading(false);
                    navigate("/dashboard");
                }
                else {
                    setLoading(false);
                    toast.error(res.data.msg);
                }
            })
        }
        setValidated(true);
    }
    const handleInputs = e => {
        setInputValue({
            ...values,
            [e.target.name]: e.target.value,
        });
    };
    return (
        <div className="homePage">
            <Helmet>
                <title>SATKARTAR:ADMIN::MEMBER LOGIN</title>
            </Helmet>
            <div className="container h-100">
                <div className="d-flex justify-content-center h-100">
                    <div className="user_card">
                        <div className="d-flex justify-content-center">
                            <div className="brand_logo_container">
                                <img src={favicon} className="brand_logo" alt="Logo" />
                            </div>
                            <h4 className="loginname">MEMBER LOGIN</h4>
                        </div>
                        <div className="d-flex justify-content-center form_container">
                            <Form noValidate validated={validated} onSubmit={handleLoginSubmit}>
                                <Form.Group as={Col} md="12" controlId="validationCustom01">
                                    <Form.Label>EMP ID</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder="Employee ID"
                                        onChange={handleInputs}
                                        name="mis_emp_id"
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        <FaRegHandPointRight /> Please provide a valid Employee ID
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md="12" controlId="validationCustom02">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control
                                        required
                                        type="password"
                                        placeholder="Password"
                                        onChange={handleInputs}
                                        name="mis_password"
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        <FaRegHandPointRight /> Please provide a valid Password
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <div className="d-flex justify-content-center mt-3 login_container">
                                    {loading ?
                                        <button disabled={true} type="submit" className="btn login_btn">
                                            <img src={loadingif} alt={loadingif} style={{ width: "7%", marginTop: "-3px" }} /> LOGIN...
                                        </button>
                                        :
                                        <button type="submit" className="btn login_btn">SUBMIT</button>
                                    }
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home
