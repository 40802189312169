import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import React, { useEffect, useRef } from 'react';
import "./App.scss";
import Home from "./pages/Home";
import Error404 from "./pages/Error404";
import Dashboard from "./pages/Dashboard";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import MyProfile from "./pages/MyProfile";
import 'react-loading-skeleton/dist/skeleton.css';
import AddNewOrder from "./pages/AddNewOrder";
import AllOrder from "./pages/AllOrder";
import Invoice from "./pages/Invoice";
import CallFollowUp from "./pages/CallFollowUp";
import AddMemberOrder from "./pages/AddMemberOrder";
import EditOrder from "./pages/EditOrder";
import PaymentHistory from "./pages/PaymentHistory";

// Component to handle inactivity logic
function AppWithInactivity() {
  const navigate = useNavigate();
  const timeoutRef = useRef(null); // To store the timeout ID
  const INACTIVITY_TIMEOUT = 5 * 60 * 1000; // 5 minutes timeout for inactivity

  // Handle user logout due to inactivity
  const handleLogout = () => {
    localStorage.clear(); // Clear all local storage data
    toast.warning("You have been logged out due to inactivity.");
    navigate("/"); // Redirect to the home or login page
  };

  // Start the inactivity timer
  const startInactivityTimer = () => {
    timeoutRef.current = setTimeout(() => {
      handleLogout();
    }, INACTIVITY_TIMEOUT);
  };

  // Reset the inactivity timer when there's user activity
  const resetInactivityTimer = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current); // Clear the existing timeout
    }
    startInactivityTimer(); // Restart the inactivity timer
  };

  // Add event listeners to capture user activity across the app
  useEffect(() => {
    document.addEventListener("mousemove", resetInactivityTimer);
    document.addEventListener("keydown", resetInactivityTimer);
    document.addEventListener("click", resetInactivityTimer);

    // Start the inactivity timer when the app loads
    startInactivityTimer();

    // Cleanup event listeners on component unmount
    return () => {
      document.removeEventListener("mousemove", resetInactivityTimer);
      document.removeEventListener("keydown", resetInactivityTimer);
      document.removeEventListener("click", resetInactivityTimer);
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current); // Clear timeout if component unmounts
      }
    };
  }, []);

  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/my-profile" element={<MyProfile />} />
        <Route path="/call-follow-up" element={<CallFollowUp />} />
        <Route path="/member-new-order" element={<AddNewOrder />} />
        <Route path="/all-orders" element={<AllOrder />} />
        <Route path="/invoice/:slug" element={<Invoice />} />
        <Route path="/add-member-order/:slug" element={<AddMemberOrder />} />
        <Route path="/edit-member-order/:slug" element={<EditOrder />} />
        <Route path="/payment-history" element={<PaymentHistory />} />
        <Route path="*" element={<Error404 />} />
      </Routes>
      <ToastContainer position="bottom-center" />
    </>
  );
}

function App() {
  return (
    <BrowserRouter>
      <AppWithInactivity />
    </BrowserRouter>
  );
}

export default App;
